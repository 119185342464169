<template>
  <section class="request--book-action">
    <div v-if="bookingInfo.listing.type === 'R'">
      <div class="content--row">
        <div>{{ $t('general.startFrom') }}</div>
        <div>{{ dateFormatStart }}</div>
      </div>
      <div class="content--row">
        <div>{{ $t('general.until') }}</div>
        <div>{{ dateFormatEnd }}</div>
      </div>
      <div class="content--row" v-if="bookingInfo.owner_discount || bookingInfo.promo_discount">
        <div>{{ $t('general.promo.oriPrice') }}</div>
        <div>{{ $n(bookingInfo.price_before_discount, 'currency', 'en-SG') }}</div>
      </div>
      <div class="content--row" v-if="bookingInfo.owner_discount">
        <div>{{ $t('general.promo.ownerDiscount') }}</div>
        <div>- {{ $n(bookingInfo.owner_discount, 'currency', 'en-SG') }}</div>
      </div>
      <div class="content--row" v-if="bookingInfo.promo_discount">
        <div>{{ $t('general.promo.promodiscount') }} ({{ bookingInfo.promo_code }})</div>
        <div>- {{ $n(bookingInfo.promo_discount, 'currency', 'en-SG') }}</div>
      </div>
      <div class="content--row">
        <div>{{ $t('general.totalPrice') }}</div>
        <div>{{ $n(bookingInfo.total_rent_price, 'currency', 'en-SG') }}</div>
      </div>
      <div class="total--row">
        <div>{{ $t('general.bookingFee') }}</div>
        <div>{{ $n(bookingInfo.booking_fee, 'currency', 'en-SG') }}</div>
      </div>
    </div>
    <div v-else>
      <div class="content--row">
        <div>{{ $t('general.price') }}</div>
        <div>
          {{
            bookingInfo.sell_price
              ? $n(bookingInfo.sell_price, 'currency', 'en-SG')
              : bookingInfo.listing.sell_price
              ? $n(
                  getPriceAfterDiscount(
                    bookingInfo.listing.discount_percentage,
                    bookingInfo.listing.discount_price,

                    bookingInfo.listing.sell_price_raw,
                  ).price_after_discount,
                  'currency',
                  'en-SG',
                )
              : 'S$ 0'
          }}
        </div>
      </div>
      <div class="total--row" v-if="!freeReservationFee">
        <div>{{ $t('general.otpFee') }}</div>
        <div>{{ bookingInfo.otp_fee }}</div>
      </div>
    </div>
    <button class="btn btn-primary" @click="$emit('submit')">
      {{ $t('booking.buy') }}
    </button>
    <b-card class="request--book-info mt-2" v-if="bookingInfo">
      <ul v-if="bookingInfo.listing.type === 'R'">
        <li>{{ $t('booking.tos.message1') }}</li>
      </ul>
      <ul v-else-if="bookingInfo.listing.type === 'S' && !freeReservationFee">
        <li>{{ $t('booking.tos_jual.message1') }}</li>
        <li>{{ $t('booking.tos_jual.message2') }}</li>
        <li>{{ $t('booking.tos_jual.message3') }}</li>
        <li>{{ $t('booking.tos_jual.message4') }}</li>
      </ul>
      <ul v-else-if="bookingInfo.listing.type === 'S' && freeReservationFee">
        <li>{{ $t('booking.tos_jual_free.message1') }}</li>
        <li>{{ $t('booking.tos_jual_free.message2') }}</li>
        <li>{{ $t('booking.tos_jual_free.message3') }}</li>
        <li>{{ $t('booking.tos_jual_free.message4') }}</li>
      </ul>
    </b-card>
  </section>
</template>

<script>
// const PromoIndicator = () => import('@/components/listings/partials/promo-indicator');
import HelperMixin from '@/mixins/helpers';

export default {
  name: 'checkout-info',
  mixins: [HelperMixin],
  props: {
    bookingInfo: {
      require: false,
    },
    freeReservationFee: {
      default: false,
    },
  },
  // components: { PromoIndicator },
  computed: {
    dateFormatStart() {
      if (this.bookingInfo) {
        let format = 'dd MMMM yyyy';
        if (this.bookingInfo.rent_period_id === 5) format = 'dd MMMM yyyy HH:mm';
        return this.$date.format(this.bookingInfo.start_date_time, format);
      }
      return '';
    },
    dateFormatEnd() {
      if (this.bookingInfo) {
        let format = 'dd MMMM yyyy';
        if (this.bookingInfo.rent_period_id === 5) format = 'dd MMMM yyyy HH:mm';
        return this.$date.format(this.bookingInfo.end_date_time, format);
      }
      return '';
    },
  },
  methods: {
    detailListing() {
      this.$router.push(this.bookingInfo.listing.web_url);
    },
  },
};
</script>

<style scoped></style>
